
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDetail, ProjectList } from '../../types/project'
import { KeepAlive } from '@/utils/decorators'

@Component
@KeepAlive
export default class List extends Vue {
  private addrList: object[] = []
  private searchInfo: {projectName: string; addr: string[]; projectType: string} = {
    projectName: '',
    addr: [],
    projectType: ''
  }

  private itemList = [
    {
      label: '项目名称',
      prop: 'projectName'
    },
    {
      label: '项目地址',
      prop: 'addr',
      show: 'template'
    },
    {
      label: '养护开始时间',
      prop: 'maintStartTime'
    },
    {
      label: '养护结束时间',
      prop: 'maintEndTime'
    },
    {
      label: '项目面积',
      prop: 'maintAcreage'
    },
    {
      label: '养护单位',
      prop: 'maintUnit'
    },
    {
      label: '项目类型',
      prop: 'projectTypeName'
    }
  ]

  private tableData: ProjectDetail[] = []
  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private projectTypeList: object[] = []

  created (): void {
    this.getAddrList()
    this.getList()
    this.getProjectTypeList()
  }

  // 搜索
  onSearch (): void {
    this.page = 1
    this.getList()
  }

  getAddrList () {
    this.$axios.get(this.$apis.common.getRegion).then(res => {
      this.addrList = res || []
    })
  }

  getProjectTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'projectType' }).then(res => {
      this.projectTypeList = res.projectType || []
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    const info = {
      projectName: this.searchInfo.projectName,
      projectProvince: this.searchInfo.addr[0] || '',
      projectCity: this.searchInfo.addr[1] || '',
      projectArea: this.searchInfo.addr[2] || '',
      projectType: this.searchInfo.projectType
    }
    this.$axios.get<ProjectList>(this.$apis.project.selectProjectByPage,
      {
        ...info,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增项目
  addProject (): void {
    this.$router.push({ name: 'projectAdd' })
  }

  // 删除项目
  deleteProject (row: ProjectDetail): void {
    this.$confirm(`是否删除项目【${row.projectName}】?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.project.deleteProject,
        {
          projectId: row.projectId
        }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
